@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;500;700&display=swap");

@keyframes text-enter {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes text-exit {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate-width-design {
  0% {
    width: 0px;
  }
  10% {
    width: 160px;
  }
  80% {
    width: 160px;
  }
  100% {
    width: 0px;
  }
}

@media (max-width: 420px) {
  @keyframes animate-width-design {
    0% {
      width: 0px;
    }
    10% {
      width: 110px;
    }
    80% {
      width: 110px;
    }
    100% {
      width: 0px;
    }
  }
}

@keyframes animate-width-marketing {
  0% {
    width: 0px;
  }
  30% {
    width: 250px;
    @media (max-width: 420px) {
      width: 140px;
    }
  }
  100% {
    width: 250px;
  }
}

@media (max-width: 420px) {
  @keyframes animate-width-marketing {
    0% {
      width: 0px;
    }
    30% {
      width: 180px;
    }
    100% {
      width: 180px;
    }
  }
}

@keyframes text-block {
  0% {
    background: white;
    color: transparent;
  }
  99% {
    background: white;
    color: transparent;
  }
  100% {
    background: none;
    color: white;
  }
}

@keyframes text-top {
  0% {
    bottom: 1em;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes text-bottom {
  0% {
    bottom: -1em;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes text-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes text-typewriter {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}

@keyframes logo-animation {
  0% {
    stroke-dashoffset: -2000;
    stroke: #ffc300;
  }
  30% {
    stroke: #ff5733;
  }
  50% {
    stroke: #ffc300;
  }
  70% {
    stroke: #ff5733;
  }
  100% {
    stroke-dashoffset: 0;
    stroke: #ffc300;
  }
}

body {
  //background: #282c34;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../images/background.jpeg");
  background-size: cover;
  background-position: center center;
  color: white;
  font-family: "Karla", sans-serif;
  margin: 0;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  width: 420px;

  @media (max-width: 420px) {
    width: 320px;
  }

  .headline-text {
    font-size: 50px;
    font-weight: bold;

    @media (max-width: 420px) {
      font-size: 35px;
    }
  }

  .headline-claim {
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 420px) {
      font-size: 18px;
    }
  }

  .char {
    position: relative;
  }

  .animation-wrapper {
    height: 130px;
  }

  .claim-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;

    > span {
      margin-left: 10px;
    }
  }

  .animation-enter {
    .char {
      width: 0;
      opacity: 0;
      animation: text-enter 1s forwards;
    }
  }

  .animation-fade {
    opacity: 0;
    animation: text-fade 0.4s forwards;
  }

  .animation-block {
    animation: animate-width-design 3s forwards;
    animation-delay: 0.4s;
    width: 0px;
    overflow: hidden;

    .char {
      color: transparent;
      animation: text-block 0.3s forwards;
    }
  }

  .animation-zipping {
    animation: animate-width-marketing 4s forwards;
    animation-delay: 0.4s;
    width: 0px;
    overflow: hidden;

    .char {
      opacity: 0;
    }

    .char:nth-child(odd) {
      animation: text-top 0.3s forwards;
    }

    .char:nth-child(even) {
      animation: text-bottom 0.8s forwards;
    }
  }

  .logo-animation {
    stroke: #ffc300;
    stroke-width: 1.25px;
    stroke-dashoffset: -2000;
    stroke-dasharray: 2000;
    animation-name: logo-animation;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  .contact-us-wrapper {
    margin-top: 30px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  .button {
    width: 150px;
    padding: 15px;
    box-sizing: border-box;
    border: 2px solid white;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;

    span {
      position: relative;
      transition: all 0.35s ease-Out;
    }

    .slide {
      width: 100%;
      height: 100%;
      left: -200px;
      background: #bfc0c0;
      position: absolute;
      transition: all 0.35s ease-Out;
      bottom: 0;
    }
  }

  .button:hover {
    .slide {
      left: 0;
    }

    span {
      color: #2d3142;
    }
  }

  .contact-us-phone {
    margin-bottom: 30px;
    img {
      height: 23px;
    }

    > div,
    > img {
      display: inline-block;
      vertical-align: middle;
    }

    div {
      margin-left: 5px;
    }
  }
}

.claim {
  margin-top: 25px;
  font-size: 25px;

  a {
    text-decoration: underline;
  }
}
